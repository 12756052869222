var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"body"}},[_vm._m(0),_c('div',{attrs:{"id":"wx_tit"}},[_vm._m(1),_vm._m(2),_c('div',{attrs:{"id":"wx_tit_main"}},[_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"weixin1"}}),_c('p',[_vm._v("扫码报修")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"dizhi"}}),_c('p',[_vm._v("在线跟踪")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"fazhanlicheng"}}),_c('p',[_vm._v("在线评价")])],1),_c('div',{attrs:{"id":"wx_tit_main_box"}},[_c('icon-svg',{staticClass:"iconclas",attrs:{"iconClass":"qianyue"}}),_c('p',[_vm._v("在线留言")])],1)]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)]),_c('div',{attrs:{"id":"wshopbox"}},[_c('p',{attrs:{"id":"on_wx"}},[_vm._v("在线体验报修小程序")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("您手机里的报修小程序，扫码报修一步到位 ")]),_c('p',{attrs:{"id":"tw_wx"}},[_vm._v("自动报修 | 在线评价 | 在线跟踪 | 在线查询")]),_c('p',{staticStyle:{"color":"#FF0000","font-size":"26px","margin":"0"},attrs:{"id":"tw_wx"}},[_vm._v("￥365")]),_c('button',{attrs:{"type":"button"},on:{"click":_vm.tyfun}},[_vm._v("立即体验")])]),_c('footers')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-banner"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bxbanner.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx_tit_header"}},[_c('p',[_vm._v("专属报修小程序，手机扫码一键报修")]),_c('span',[_vm._v("大总管智能报修小程序，能让您实现扫码一步报修，在线跟踪工单状态，在线评价服务质量提升您的售后服务体验")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wx-img"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phonebx.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"550PX"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonebx001.png"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"sub"}},[_vm._v("微信报修")]),_c('p',{attrs:{"id":"Ti"}},[_vm._v("微信小程序 扫码报修")]),_c('span',[_vm._v("让您售后无忧 设备坏了无需找什么资料，保修卡发票，一键扫描，在线查询商品的保修期，系统自动识别您的信息，自动为您分配最近的服务人员让您全程实时掌控。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonebx002.jpg"}}),_c('div',{staticStyle:{"margin-top":"15%"},attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("短信通知")]),_c('span',[_vm._v("从报修开始大总管报修平台将实时把维修人员，维修的动态过程以微信消息的方式通知您，让您实时可以查看到维修人员给您设备维修的状态。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"550PX","margin-top":"-125px"},attrs:{"id":"WX-shop"}},[_c('img',{attrs:{"src":"https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bxpj.jpg"}}),_c('div',{attrs:{"id":"WX-shop_NAME"}},[_c('p',{attrs:{"id":"Ti"}},[_vm._v("在线评价")]),_c('span',[_vm._v("提升您的服务体验，让您对工程师的服务可以在线评价，可以实时反馈信息到公司平台，开通实时投诉通道，另有在线知识库可以让您随时查询您的设备的说明书以及您的维修记录")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"0"},attrs:{"id":"paybgc"}},[_c('div',{staticStyle:{"background":"rgba(0,0,0,0.5)","width":"100%","height":"100%","padding-top":"15%"}},[_c('i',{staticClass:"el-icon-circle-check"}),_c('p',[_vm._v("帮你开台微信报修小程序")]),_c('span',[_vm._v("扫码报修"),_c('br'),_vm._v(" 自动对接您的PC端报修系统，让您的微信报修工单，自动和您报修后台程序，和工程师的维修端自动处理，让您实现，报修、接单的自动化")])])])
}]

export { render, staticRenderFns }